/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import discord from '../../assets/images/discord (3).png';

export default function Footer() {
  return (
    <div className="footer">
      <div className="w-11/12 ml-auto mr-auto  py-20 xl:py-20 ">
        <div className="text-center">
          <ul className=" inline-flex ">
            <li>
              {' '}
              <a className="text-tiny f-f-m mr-3 sm:mr-8 cursor-pointer">
                {' '}
                Blog
              </a>{' '}
            </li>
            <li>
              {' '}
              <a className="text-tiny  f-f-m mr-3 sm:mr-8 cursor-pointer ">
                {' '}
                Docs
              </a>{' '}
            </li>
            <li>
              {' '}
              <a className="text-tiny  f-f-m mr-3 sm:mr-8 cursor-pointer  ">
                {' '}
                Audit
              </a>{' '}
            </li>
            <li>
              {' '}
              <a className="text-tiny f-f-m mr-3 sm:mr-8 cursor-pointer  ">
                {' '}
                Media Kit
              </a>{' '}
            </li>
          </ul>
          <div>
            <ul className=" inline-flex  mt-5 ">
              <li>
                {' '}
                <a
                  className="text-tiny  f-f-m text-white cursor-pointer"
                  href="https://github.com/JonahValidE/GuavaPublic">
                  {' '}
                  <i className="fa fa-github fa-lg " aria-hidden="true" />{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                <a
                  className="text-tiny  f-f-m text-white ml-12  cursor-pointer"
                  href="https://t.me/anonydoxx">
                  {' '}
                  <i className="fa fa-telegram fa-lg" aria-hidden="true" />
                </a>{' '}
              </li>
              <li>
                {' '}
                <a
                  className="text-tiny  f-f-m text-white cursor-pointer"
                  href="https://discord.gg/dsp2GYhh">
                  {' '}
                  <img
                    src={discord}
                    height="auto"
                    width="30px"
                    className="ml-12"
                  />{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                <a
                  className="text-tiny  f-f-m text-white    cursor-pointer ml-12"
                  href="https://twitter.com/AnonyDoxx">
                  <i className="fa fa-twitter fa-lg" aria-hidden="true" />
                </a>{' '}
              </li>
              <li>
                {' '}
                <a className="text-tiny  f-f-m text-white   cursor-pointer ml-12 ">
                  <i className="fa fa-reddit fa-lg" aria-hidden="true" />
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

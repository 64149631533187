import React from 'react';

import Home from './components/home/index';

import './assets/css/app.css';

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;

import React from 'react';

export default function Bifitoken() {
  return (
    <div className="guavasect">
      <div className="w-6/12 ml-auto mr-auto  py-14 ">
        <h1 className="text-xl f-f-m text-center my-3">Guava Token</h1>
        <h1 className="text-tiny f-f-r text-center">
          Guava is the native revenue-share & governance token for our protocol.
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 h-auto mt-4">
          <div className="">
            {/* cardsection started */}
            <div className=" border border-white  navbg rounded-xl  p-5  h-full relative card-bg add-shadow">
              <h2 className=" text-white-light f-f-r text-base ">Guava</h2>
              <h1 className=" text-tiny text-white f-f-r  mb-16">
                Having some Guava, gives you access to our ecosystem, allowing
                you to upgrade your crypto game!
              </h1>
              <button
                type="button"
                className="absolute bottom-5 left-1/2 -translate-x-1/2 underline bg-green text-white py-3 px-6   text-tiny f-f-m  rounded-lg w-[calc(100%-24px)] mt-5 ">
                Buy Guava
              </button>
            </div>
            {/* cardsection started */}
          </div>
          <div className="h-full">
            {/* cardsection started */}
            <div className=" border border-white navbg rounded-xl  p-5  h-full relative card-bg add-shadow">
              <h2 className=" text-white-light f-f-r text-base ">Support</h2>
              <h1 className=" text-tiny text-white f-f-r  mb-16">
                There are many ways to request support, click below to find our
                contact hubs.
              </h1>
              <button
                type="button"
                className="absolute bottom-5 left-1/2 -translate-x-1/2 underline text-default bg-green text-white py-3 px-6   text-tiny f-f-m  rounded-lg w-[calc(100%-24px)] mt-5 ">
                Contact us!
              </button>
            </div>
            {/* cardsection started */}
          </div>
          <div className="h-full">
            {/* cardsection started */}
            <div className=" border border-white  navbg rounded-xl  p-5  h-full relative card-bg add-shadow">
              <h2 className=" text-white-light f-f-r text-base ">Vote</h2>
              <h1 className=" text-tiny text-white f-f-r  mb-16">
                Our ecosystem is run using our DAO, giving the decision power to
                holders. Decentralised management!
              </h1>
              <button
                type="button"
                className="absolute bottom-5 left-1/2 -translate-x-1/2 underline bg-green text-white py-3 px-6   text-tiny f-f-m  rounded-lg w-[calc(100%-24px)] mt-5 ">
                Vote
              </button>
            </div>
            {/* cardsection started */}
          </div>
          <div className="h-full">
            {/* cardsection started */}
            <div className=" border border-white  navbg rounded-xl  p-5  h-full relative card-bg add-shadow">
              <h2 className=" text-white-light f-f-r text-base ">
                Guava Staking
              </h2>
              <h1 className=" text-tiny text-white f-f-r  mb-16">
                Guava holders share in our revenue by staking their Guava for
                SweetTea DAO tokens!
              </h1>
              <button
                type="button"
                className="absolute bottom-5 left-1/2 -translate-x-1/2 underline bg-green text-white py-3 px-6 text-tiny f-f-m  rounded-lg w-[calc(100%-24px)] mt-5">
                Coming soon!
              </button>
            </div>
            {/* cardsection started */}
          </div>
        </div>
      </div>
    </div>
  );
}

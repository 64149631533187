/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function Tabssection() {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <div className="tabbbs">
      <div className="w-6/12 ml-auto mr-auto pt-6 pb-14">
        <h1 className="text-xl f-f-m text-center mb-4">
          Do more with your Holdings
        </h1>
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="no-scrollbar overflow-x-auto">
              <div className="max-w-[640px] min-w-[640px] ml-auto mr-auto">
                <ul
                  className="flex list-none flex-wrap flex-row border border-size-1 mb-10 add-shadow rounded "
                  role="tablist">
                  <li className=" flex-auto text-center">
                    <a
                      className={`text-tiny f-f-r  px-5 py-2 rounded block leading-normal ${
                        openTab === 1
                          ? 'text-white inactive-tab'
                          : ' text-darken bold '
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle="tab"
                      href="#link1"
                      role="tablist">
                      Chart
                    </a>
                  </li>
                  <li className=" flex-auto text-center">
                    <a
                      className={`text-tiny f-f-r  px-5 py-2 rounded block leading-normal ${
                        openTab === 2
                          ? 'text-white inactive-tab'
                          : ' text-darken bold '
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist">
                      Certify
                    </a>
                  </li>
                  <li className=" flex-auto text-center">
                    <a
                      className={`text-tiny f-f-r  px-5 py-2 rounded block leading-normal ${
                        openTab === 3
                          ? 'text-white inactive-tab'
                          : ' text-darken bold '
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist">
                      Collaborate
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative flex flex-col min-w-0 break-words  w-10/12 ml-auto mr-auto  xl:w-full mb-6  rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? 'block' : 'hidden'}
                    id="link1">
                    {/* tab inner section started */}
                    <div className="grid grid-cols-12 gap-5 ">
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <img src="../../assets/images/guava2.png" />
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            View
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Assets across multiple chains, in one convenient
                            place!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <img src="./guava2.png" />
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Manage
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Transaction history for easy budgeting and tax
                            declarations!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <img src="./guava2.png" />
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Transfer
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Crosschain token swaps, easy peasy!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <img src="./guava2.png" />
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Trade
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Buy, sell & swap your holdings
                          </h2>
                        </div>
                      </div>
                    </div>

                    {/* tab inner section ended */}
                  </div>
                  <div
                    className={openTab === 2 ? 'block' : 'hidden'}
                    id="link2">
                    {/* tab inner section started */}
                    <div className="grid grid-cols-12 gap-5 ">
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52   ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              1
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Access
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Access the anonymous KYC portal, secured by
                            ValidEntry.
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              2
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Validate
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Every user is verified with their wallet and ID,
                            we're all human here!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              3
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Create
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Create your profile, showcase your talents to other
                            verified users.
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              4
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Engage
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Create or join a verified workforce!
                          </h2>
                        </div>
                      </div>
                    </div>

                    {/* tab inner section ended */}
                  </div>
                  <div
                    className={openTab === 3 ? 'block' : 'hidden'}
                    id="link3">
                    {/* tab inner section started */}
                    <div className="grid grid-cols-12 gap-5 ">
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52   ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              1
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Connect
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Find or create a team to make your goals a reality!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              2
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Communicate
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Talk to your team, through our secure and convenient
                            chat portal, specifically built for remote teamwork!
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              3
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Post
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            Positions, updates and advertisements.
                          </h2>
                        </div>
                      </div>
                      <div className=" col-span-12 sm:col-span-6 xl:col-span-3 ">
                        <div className=" border border-white navbg p-5 rounded-xl card-bg add-shadow h-52 ">
                          <div className="h-8 w-8 bg-white rounded-full ">
                            <h1 className="text-center text-blue-main text-base ">
                              4
                            </h1>
                          </div>
                          <h2 className=" text-white f-f-r text-base pt-2 ">
                            Opportunity
                          </h2>
                          <h2 className=" text-white f-f-r text-tiny pt-2 ">
                            At your fingertips, the ecosystems empowers
                            networking and innovation, to build your business.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

import Bifitoken from './Bifitoken';
import Footer from './Footer';
import Header from './header';
import Modals from './Modals';
import Tabssection from './Tabssection';

export default function index() {
  return (
    <div>
      <Header />
      <Tabssection />
      <Bifitoken />
      <Modals />
      <Footer />
    </div>
  );
}

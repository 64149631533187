import React from 'react';

import Logo2 from '../../assets/images/guava2.png';
import smalllogo from '../../assets/images/small.png';

export default function ButtonAppBar() {
  return (
    <div className="header">
      <div className="w-11/12 ml-auto mr-auto py-5 fullscrn">
        <div className="grid grid-cols-12">
          <div className="col-span-7   md:col-span-8 lg:col-span-9 ">
            <a href="/" className="flex items-center">
              <img src={Logo2} width="117px" height="40px" />
            </a>
          </div>
          <div className="col-span-5  md:col-span-4 lg:col-span-3 ">
            <div className="text-right">
              <ul className="hidden sm:inline-flex translate-y-1.5">
                <li>
                  <img src={smalllogo} width="24px" height="24px" />
                </li>
                <li>
                  <h1 className=" text-tiny text-white  ml-2  ">
                    Launching Soon!
                  </h1>
                </li>
              </ul>
              <button
                type="button"
                className="bg-white text-blue-main py-2 px-4  ml-4 add-shadow rounded-xl f-f-r ">
                Launch App
              </button>
            </div>
          </div>
        </div>

        {/* hero section started */}
        <div className="relative py-1 md:py-72 lg:py-100 xl:py-100">
          <h2 className=" text-3xl sm:text-6xl text-center f-f-b z-10 lg:py-5">
            Your DeFi Analytics
          </h2>
          <h2 className=" text-3xl sm:text-6xl text-center f-f-b ">
            Dashboard
          </h2>
          <div className=" md:w-5/12 ml-auto mr-auto mt-7 ">
            <p className=" text-base text-center f-f-r ">
              Creating an Internet of Value across DeFi
            </p>
          </div>
          <div className=" text-center my-10 lg:py-5 ">
            <ul className="inline-flex">
              <li>
                <button
                  type="button"
                  className=" card-bg border border-white text-darken py-3 px-6 ml-4 text-tiny f-f-m add-shadow rounded-xl ">
                  Launch App
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="border border-white text-darken py-3 px-6 add-shadow ml-4 f-f-m rounded-xl ">
                  Litepaper
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* hero section ended */}
      </div>
    </div>
  );
}

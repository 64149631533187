import React from 'react';

import copy from '../../assets/images/copy.webp';
import pcs from '../../assets/images/pcs.png';
import small from '../../assets/images/small.png';
import uni from '../../assets/images/uni.jpg';

export default function Modals() {
  return (
    <div className="modal add-shadow">
      <div className="modal1">
        <div className="titolo">
          <img className="smallIMGtitolo" src={small} />
          <p>How to buy Guava</p>
          <p>- 0x00000000000000000000</p>
          <button type="button" className="copyBTN">
            <img className="copyIMG" src={copy} />
          </button>
        </div>
        <div className="modalrows">
          <div>
            <img src={pcs} className="dexicons" />
          </div>
          <div className="dexInfo">
            <p className="dexTitle">PancakeSwap</p>
            <div className="dexbio">
              <p>
                PancakeSwap is one of the most trusted crypto swapping services
                around. To buy with PCS simply click the link below, connect to
                your favourite wallet, and purchase Guava on whichever chain you
                want!
              </p>
            </div>
            <div>
              <p className="dexlink">https://www.pancakeswap.finance/</p>
            </div>
          </div>
        </div>
        <div className="modalrows">
          <div>
            <img src={uni} className="dexicons" />
          </div>
          <div className="dexInfo">
            <p className="dexTitle">Uniswap</p>
            <div className="dexbio">
              <p>
                Uniswap is one of the most trusted crypto swapping services
                around. To buy with UNI simply click the link below, connect to
                your favourite wallet, and purchase Guava on whichever chain you
                want!
              </p>
            </div>
            <div>
              <p className="dexlink">https://app.uniswap.org/#/swap</p>
            </div>
          </div>
        </div>
        <div className="modalrows">
          <div>
            <img src={pcs} className="dexicons" />
          </div>
          <div className="dexInfo">
            <p className="dexTitle">PancakeSwap</p>
            <div className="dexbio">
              <p>
                PancakeSwap is one of the most trusted crypto swapping services
                around. To buy with PCS simply click the link below, connect to
                your favourite wallet, and purchase Guava on whichever chain you
                want!
              </p>
            </div>
            <div>
              <p className="dexlink">https://www.pancakeswap.finance/</p>
            </div>
          </div>
        </div>
        <div className="modalrows">
          <div>
            <img src={uni} className="dexicons" />
          </div>
          <div className="dexInfo">
            <p className="dexTitle">Uniswap</p>
            <div className="dexbio">
              <p>
                Uniswap is one of the most trusted crypto swapping services
                around. To buy with UNI simply click the link below, connect to
                your favourite wallet, and purchase Guava on whichever chain you
                want!
              </p>
            </div>
            <div>
              <p className="dexlink">https://app.uniswap.org/#/swap</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
